.pagina {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 20px;
    display: flex;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}

.pagina table {
    border-collapse: collapse;
    width: 100%;
}

.pagina th,
.pagina td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

.pagina tr:hover {
    background-color: #f5f5f5;
}

.pagina div,
.pagina th {
    text-align: center;
}
.requisicao{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;

}
.left{
    padding-top: 7vh;
    padding-left: 2vw;
    width: 20%;
}
.logoUem{
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: block;
    margin-top: 15px;
    margin-bottom: 20px;
}
.titulo1{
    font-size: 1.7vw;
    text-align: left;
    margin-top: 3vh;
    margin-bottom: 10vh;
    color: green;
}

.right{
    width: 80%;
    padding-top: 7vh;
    padding-left: 2vw;
    padding-right: 8vw;
    padding-bottom: 2vh;
    box-sizing: border-box;
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: green;
    padding-bottom: 18vh;
}
.nav-destiny{
    margin-left: 55%;
    padding-top: 1.5vh;
}
.txtND{
    font-size: 12pt;
    color: #000;
    margin-bottom: 0;
}

.txtNT{
    font-size: 12pt;
    text-decoration: underline;
    font-weight: 700;
    color: #000;
    margin-top: 2vh;
}

.nav-info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
}

.txtNI{
    font-size: 12pt;
    color: #000;
    margin-bottom: 0;
    font-style: italic;
}

.body{
    margin-top: 4vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1vh;
}

.txtBD{
    font-size: 12pt;
    color: #000;
    margin-bottom: 0;
}


.container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1vw;
    margin-bottom: 4vh;
}
.container-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    margin: 0;
    padding: 0;
}
.container-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    margin: 0;
    padding: 0;
}
.pagina ol, ul{
    margin-left: 0vw;
    padding-left: 1vw;
    line-height: 2;
}

.footer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
}

.assinatura{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 5vh;
}

.txtSS{
    margin-top: 3.5vh;
}

.hidd{
    display: none;
}

.qrCode{
    position: absolute;
    top: 15px;
  right: 20px;
  margin: 15px;
}